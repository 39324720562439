import React, { useState, useEffect, useMemo, memo, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectMenuProps, tbdDaysOptions, useClasses } from '../../../../../config';
import styles from './styles';
import { FormControl, Grid, TextField, MenuItem, Select as MuiSelect, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Tooltip, Button, InputLabel } from '@mui/material';
import DatePicker from 'react-date-picker';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { dateForBackend, formatDate, formatDateForRange } from '../../../../../config/utils';
import { getCoursePrice, getCoursesPrices } from '../../../../../services/pricing';
import { addCourse, deleteCourse } from '../../../../../services/courses';
import { quotesActions } from '../../../../../redux';
import { defaultTimeSlots, hours, minutes, numberOfGolfersOptions } from './../../../../../config/constants';
import { toastr } from 'react-redux-toastr';
import { GeneralAutocomplete } from '../../../../../components/autocomplete';
import Select from 'react-select';


const Courses = (props) => {

    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseList, setCourseList] = useState([]);
    const [tableCourseList, setTableCourseList] = useState([]);
    const [dateTime, setDateTime] = useState(new Date());

    const { contentBox, itemId, itemPackage, updatePackage, updateItem, resortPackages, selectedCourses, maxGolfers } = props;
    const classes = useClasses(styles);

    let selectedCoursesAvailable = selectedCourses && Object.keys(selectedCourses).length !== 0 && itemId in selectedCourses;

    useEffect(() => {
        if (props.itemDate.length === 2) {
            setDateTime(props.itemDate[0]);
        }
    }, [props.itemDate]);

    useEffect(() => {
        if (itemPackage) {
            setSelectedCourse(null);
            fetchCoursesWithPrices(itemPackage.value, dateTime);
        }
    }, [itemPackage]);

    const fetchCoursesWithPrices = (packageId, date) => {
        let payload = { vacationItemId: itemId, packageId: packageId, date: dateForBackend(date), nrGolfers: maxGolfers };
        getCoursesPrices(payload)
            .then(response => {
                if (response.status === 200) {
                    if (selectedCourse) {
                        let newSelectedCourse = response.data.filter(e => e.id == selectedCourse.id);
                        if (newSelectedCourse.length > 0) {
                            setSelectedCourse(newSelectedCourse[0]);
                        }
                    }
                    setCourseList(response.data);
                    let formattedCourseList = response.data.map(course => ({ value: course.id, label: course.title }));
                    setTableCourseList(formattedCourseList);
                }
                else {
                    toastr.error('', 'Could not retrieve prices for courses');
                }
            });
    }

    const handleUpdatePackage = (value) => {
        updatePackage(value);
        setSelectedCourse(null);
    }

    const resetSelectedPackage = () => {
        setSelectedCourse(null);
        setCourseList([]);
        updatePackage(null);
    }

    const handleUpdateCourse = (course) => {
        let payload = {
            id: course.internal_id,
            vacation_item_id: itemId,
            course_id: course.course.value,
            course_title: course.course.label,
            course_date: course.date_time,
            course_hour: course.hour,
            course_minute: course.minute,
            price: course.price,
            nr_golfers: course.nr_golfers,
            nr_days: course.tbd_days, // is this tbd_days? 
            tbd_timezone: course.tbd,
            tee_10: course['10th'] === false ? 'off' : 'on',
            tee_14: 'off', // I don't receive it from the getQuoteById endpoint
            surcharge: course.surcharge === true ? 'on' : 'off',
            markup: course.markup,
            order_id: 0, // is this necessary? 
            automaticPricing: course.automaticPricing
        }

        props.saveSelectedCourse({ itemId: itemId, id: course.internal_id, body: payload })
    }

    const changeTitleAndUpdatePrice = (course, value) => {

        if (!course.automaticPricing) {
            console.log('Do not make the API call because automaticPricing is set to ', course.automaticPricing);

            props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: { ...course, course: value, titleOpen: false } });
            handleUpdateCourse({ ...course, course: value, titleOpen: false });
            return;
        }

        let noRateCourse = { ...course, course: value, price: 0, markup: 0, titleOpen: false };
        let params = { courseId: course.internal_id, vacationItemId: itemId, date: dateForBackend(course.date_time), time: `${course.hour}:${course.minute}`, nrGolfers: course.nr_golfers === -1 ? maxGolfers : course.nr_golfers };
        getCoursePrice({ id: value.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data.course.rates[0];
                    if (rate) {
                        let updatedCourse = { ...course, course: value, price: rate.price, markup: rate.markup, titleOpen: false }
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourse });
                        handleUpdateCourse(updatedCourse);
                        if (rate.selectedCoursesId) {
                            let twoRoundsCourse = selectedCourses[itemId].find(e => e.internal_id == rate.selectedCoursesId[0]);
                            if (twoRoundsCourse && twoRoundsCourse.price != 0) {
                                props.updateTwoRoundsCourse({ itemId: itemId, id: rate.selectedCoursesId[0] });
                                handleUpdateCourse({ ...twoRoundsCourse, price: 0, markup: 0 });
                            }
                        }
                    } else {
                        console.log('No rate received');
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: noRateCourse });
                        handleUpdateCourse(noRateCourse);
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve new price');
                    props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: noRateCourse });
                    handleUpdateCourse(noRateCourse);
                }
            });
    }

    const changeDateAndUpdatePrice = (course, value) => {

        if (!course.automaticPricing) {
            console.log('Do not make the API call because automaticPricing is set to ', course.automaticPricing);

            updateDateWhenNoRateIsReceived(course, value);
            return;
        }

        let params = { courseId: course.internal_id, vacationItemId: itemId, date: dateForBackend(value), time: `${course.hour}:${course.minute}`, nrGolfers: course.nr_golfers === -1 ? maxGolfers : course.nr_golfers };
        getCoursePrice({ id: course.course.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data.course.rates[0];
                    if (rate) {
                        let updatedCourse = { ...course, date_time: formatDate(value), price: rate.price, markup: rate.markup }
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourse, isDateTimeChanged: true });
                        handleUpdateCourse(updatedCourse);
                        if (rate.selectedCoursesId) {
                            let twoRoundsCourse = selectedCourses[itemId].find(e => e.internal_id == rate.selectedCoursesId[0]);
                            if (twoRoundsCourse && twoRoundsCourse.price != 0) {
                                props.updateTwoRoundsCourse({ itemId: itemId, id: rate.selectedCoursesId[0] });
                                handleUpdateCourse({ ...twoRoundsCourse, price: 0, markup: 0 });
                            }
                        }
                    } else {
                        console.log('No rate received');
                        updateDateWhenNoRateIsReceived(course, value);
                    }
                }
                else {
                    console.log('error')
                    toastr.error('', 'Could not retrieve prices for courses');
                    updateDateWhenNoRateIsReceived(course, value);
                }
            })
    }

    let updateDateWhenNoRateIsReceived = (course, value) => {
        let formattedDate = formatDate(value);
        props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'date_time', value: formattedDate });
        handleUpdateCourse({ ...course, date_time: formattedDate });
    }

    const changeTimeAndUpdatePrice = (course, hour, minute) => {

        let updatedCourseNoRate = { ...course, hour: hour, minute: minute };
        if (!course.automaticPricing) {
            console.log('Do not make the API call because automaticPricing is set to ', course.automaticPricing);

            props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourseNoRate, isDateTimeChanged: true });
            handleUpdateCourse(updatedCourseNoRate);
            return;
        }

        let params = { courseId: course.internal_id, vacationItemId: itemId, date: dateForBackend(course.date_time), time: `${hour}:${minute}`, nrGolfers: course.nr_golfers === -1 ? maxGolfers : course.nr_golfers };
        getCoursePrice({ id: course.course.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data.course.rates[0];
                    //TODO: remove check once the defaults are in place 
                    if (rate) {
                        let updatedCourse = { ...course, hour: hour, minute: minute, price: rate.price, markup: rate.markup }
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourse, isDateTimeChanged: true });
                        handleUpdateCourse(updatedCourse);
                        if (rate.selectedCoursesId) {
                            let twoRoundsCourse = selectedCourses[itemId].find(e => e.internal_id == rate.selectedCoursesId[0]);
                            if (twoRoundsCourse && twoRoundsCourse.price != 0) {
                                props.updateTwoRoundsCourse({ itemId: itemId, id: rate.selectedCoursesId[0] });
                                handleUpdateCourse({ ...twoRoundsCourse, price: 0, markup: 0 });
                            }
                        }
                    } else {
                        console.log('No rate received');
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourseNoRate, isDateTimeChanged: true });
                        handleUpdateCourse(updatedCourseNoRate);
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve new price');
                    props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourseNoRate, isDateTimeChanged: true });
                    handleUpdateCourse(updatedCourseNoRate);
                }
            });
    }

    const changeNrGolfersAndUpdatePrice = (course, value) => {

        if (!course.automaticPricing) {
            console.log('Do not make the API call because automaticPricing is set to ', course.automaticPricing);

            updateNrGolfersWhenNoRateIsReceived(course, value);
            return;
        }

        let params = { courseId: course.internal_id, vacationItemId: itemId, date: dateForBackend(course.date_time), time: `${course.hour}:${course.minute}`, nrGolfers: value === -1 ? maxGolfers : value };
        getCoursePrice({ id: course.course.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data.course.rates[0];
                    if (rate) {
                        let updatedCourse = { ...course, nr_golfers: value, price: rate.price, markup: rate.markup }
                        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourse });
                        handleUpdateCourse(updatedCourse);
                        if (rate.selectedCoursesId) {
                            let twoRoundsCourse = selectedCourses[itemId].find(e => e.internal_id == rate.selectedCoursesId[0]);
                            if (twoRoundsCourse && twoRoundsCourse.price != 0) {
                                props.updateTwoRoundsCourse({ itemId: itemId, id: rate.selectedCoursesId[0] });
                                handleUpdateCourse({ ...twoRoundsCourse, price: 0, markup: 0 });
                            }
                        }
                    } else {
                        console.log('No rate received');
                        updateNrGolfersWhenNoRateIsReceived(course, value);
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve prices for courses');
                    updateNrGolfersWhenNoRateIsReceived(course, value);
                }
            });
    }

    let updateNrGolfersWhenNoRateIsReceived = (course, value) => {
        props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'nr_golfers', value: value });
        handleUpdateCourse({ ...course, nr_golfers: value });
    }

    const columns = useMemo(
        () => ['Title', 'Date', 'Time', '10th tee', 'TBD', 'GF/SC', 'Cost', 'Markup', 'Price', 'Golfers', 'APA', ''], []
    );

    const totalsColumns = useMemo(
        () => ['Total', 'Without markup', 'Markup'], []
    );

    const getGolfersOptions = () => {
        let options = [{ label: 'All', value: -1 }];
        numberOfGolfersOptions.slice(1, maxGolfers + 1).forEach(option => {
            options.push({ label: option.label, value: option.value });
        })
        return options;
    }

    const getTotalPrice = (cost, markup) => {
        const total = parseFloat(cost) + parseFloat(markup) / 100 * parseFloat(cost);
        return total.toFixed(2);
    }

    const updateTbd = (value, courseId) => {
        let tbd = value;
        let tbd_days = value;
        if (value === 'off') {
            props.updateSelectedCourseTbd({ itemId: itemId, id: courseId, tbd: value, tbd_days: 0 });
            tbd_days = 0;
        } else {
            props.updateSelectedCourseTbd({ itemId: itemId, id: courseId, tbd: 'on', tbd_days: value });
            tbd = 'on';
        }
        let course = selectedCourses[itemId].find(e => e.internal_id == courseId);
        handleUpdateCourse({ ...course, tbd, tbd_days });
    }

    const handleAddNewCourse = (startTime, price, markup, selectedCoursesId) => {
        let time = startTime.split(":");
        if (time.length !== 2) {
            toastr.error('', 'Failed to add course due to time range issues!')
        }
        let newEntry = {
            id: 0,
            vacation_item_id: itemId,
            course_id: selectedCourse.id,
            course_title: selectedCourse.title,
            course_date: dateTime, //format date here 
            course_hour: time[0],
            course_minute: time[1],
            price: price == 'Blackout' ? 0 : price,
            nr_golfers: -1,
            nr_days: 0,
            tbd_timezone: 'off',
            tee_10: 'off',
            tee_14: 'off',
            surchange: 'off',
            markup: markup,
            order_id: 0,
        }

        if (selectedCoursesId) {
            newEntry.selectedCoursesId = selectedCoursesId
        }

        addNewCourse(newEntry, selectedCoursesId);
    }

    const addNewCourse = (course, selectedCoursesId) => {
        addCourse(course)
            .then(response => {
                if (response.status === 200) {
                    props.addSelectedCourse({ newEntry: response.data, itemId: itemId });
                    if (selectedCoursesId) {
                        props.updateTwoRoundsCourse({ itemId: itemId, id: selectedCoursesId[0] });
                    }
                    fetchCoursesWithPrices(itemPackage.value, dateTime);
                }
                else {
                    toastr.error('', 'The course could not be added');
                }
            });
    }

    const handleDeleteCourse = (course) => {
        deleteCourse({ id: course.internal_id })
            .then(response => {
                if (response.status === 200) {
                    props.deleteSelectedCourse({ itemId: itemId, id: course.internal_id })
                    fetchCoursesWithPrices(itemPackage.value, dateTime);
                }
                else {
                    toastr.error('', 'The course could not be deleted');
                }
            });
    }

    const handleAddDefaultCourse = (startHour) => {
        if (itemPackage && selectedCourse) {
            handleAddNewCourse(startHour, 0, 0, null);
        } else {
            toastr.warning('', 'Choose a package and a course in order to add a new entry!');
        }
    }

    const totals = selectedCoursesAvailable ? selectedCourses[itemId].reduce((acc, item) => {
        let cost = parseFloat(item.price || 0);
        let markup = parseFloat(item.markup || 0);

        acc.total = acc.total + (cost + cost * markup / 100);
        acc.markup = acc.markup + (cost * markup / 100);
        acc.withoutMarkup = acc.withoutMarkup + cost;

        return acc;
    }, { total: 0, units: 0, nights: 0, markup: 0, withoutMarkup: 0 })
        : { total: 0, units: 0, nights: 0, markup: 0, withoutMarkup: 0 };

    const handleBoxDateChange = (value) => {
        //TODO: check Date Format to be aligned with the one from lodging 
        let formattedValue = formatDate(value);
        setDateTime(formattedValue);

        fetchCoursesWithPrices(itemPackage.value, value);
    }

    const resetPrice = (course) => {
        let updatedCourse = { ...course, price: 0, markup: 0, tbd: 'off', tbd_days: 0, surcharge: false, nr_golfers: -1 };
        props.replaceSelectedCourse({ itemId: itemId, id: course.internal_id, course: updatedCourse });
        handleUpdateCourse(updatedCourse);
    }

    const DefaultTableRows = () => {
        return defaultTimeSlots.map((slot, index) => {
            const startHour = slot.split(" - ")[0];
            return (
                <TableRow key={index}>
                    <TableCell>{slot}</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleAddDefaultCourse(startHour)}>
                            <AddBoxIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Grid container spacing={2} className={classes.coursesContainer}>
            <Grid item xs={4}>
                <div className={classes.card}>
                    <TextField
                        label='Course Content Box'
                        value={contentBox}
                        onChange={(e) => updateItem({ target: 'content_box', value: e.target.value, id: itemId })}
                        fullWidth
                        size='small'
                        className={classes.contentBox}
                        multiline={true}
                    />
                    <div className={classes.fieldAndIconContainer}>
                        <GeneralAutocomplete
                            label='Package'
                            options={resortPackages}
                            value={itemPackage}
                            isLoading={resortPackages.length === 0}
                            handleChange={(value) => handleUpdatePackage(value)}
                            optionKey='label'
                        />
                        <IconButton onClick={resetSelectedPackage}><RefreshIcon sx={{ color: 'black' }} /></IconButton>
                    </div>
                    <div className={classes.dateRangeContainer}>
                        <span className={classes.dateRangeLabel}>Date</span>
                        <DatePicker
                            value={dateTime}
                            onChange={(value) => handleBoxDateChange(value)}
                            calendarIcon={dateTime ? formatDateForRange(dateTime) : 'Choose a date'}
                            locale='en-US'
                            clearIcon={null}
                            className={classes.dateRangeInput}
                        />
                    </div>
                    <div className={classes.courseAndIconContainer}>
                        <GeneralAutocomplete
                            label='Course'
                            options={courseList}
                            value={selectedCourse}
                            isLoading={courseList.length === 0}
                            handleChange={(value) => setSelectedCourse(value)}
                            optionKey='title'
                        />
                        <Tooltip title={selectedCourse ? selectedCourse.description : ''}>
                            <IconButton><NotListedLocationIcon sx={{ color: 'black' }} /></IconButton>
                        </Tooltip>
                    </div>
                    <TableContainer component={Paper} className={classes.overflowContainer}>
                        <Table size='small'>
                            <TableHead className={classes.courseTableHead}>
                                <TableRow>
                                    <TableCell>Time Range</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Markup</TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedCourse && selectedCourse.rates.length > 0 ? selectedCourse.rates.map((entry, index) => {
                                    return <TableRow key={index}>
                                        <TableCell> {`${entry.startTime} - ${entry.endTime}`}</TableCell>
                                        <TableCell> {entry.price}</TableCell>
                                        <TableCell> {entry.markup}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleAddNewCourse(entry.startTime, entry.price, entry.markup, entry.selectedCoursesId)}>
                                                <AddBoxIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                })
                                    : <DefaultTableRows />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
            <Grid item xs={8}>
                <TableContainer component={Paper} className={classes.coursesTableContainer} sx={{ boxShadow: 'none' }}>
                    <Table className={classes.coursesTable}>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                {columns.map(col => <TableCell align='center' key={col}>{col}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedCoursesAvailable && selectedCourses[itemId].map((course, index) => {
                                return <TableRow key={course.internal_id}>
                                    <TableCell className={classes.coursesTitleColumn} align='center'>
                                        {course.titleOpen ?
                                            <Fragment>
                                                <InputLabel
                                                    className={classes.closeLabel}
                                                    onClick={() => props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'titleOpen', value: false })}
                                                >Close</InputLabel>
                                                <Select
                                                    value={course.course}
                                                    onChange={(value) => changeTitleAndUpdatePrice(course, value)}
                                                    options={tableCourseList}
                                                    searchable={true}
                                                />
                                            </Fragment>
                                            :
                                            <span onClick={() => props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'titleOpen', value: true })}>
                                                {course.course.label}
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell align='center' sx={{ minWidth: 100 }}>
                                        <DatePicker
                                            value={course.date_time}
                                            onChange={(value) => changeDateAndUpdatePrice(course, value)}
                                            calendarIcon={course.date_time ? formatDateForRange(course.date_time) : 'Choose a date'}
                                            locale='en-US'
                                            clearIcon={null}
                                            className={`${classes.dateRangeInput} ${classes.courseTableDate}`}
                                            calendarClassName={classes.courseTableCalendar}
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <div className={classes.coursesTime}>
                                            <select
                                                value={course.hour}
                                                onChange={(e) => changeTimeAndUpdatePrice(course, e.target.value, course.minute)}
                                                className={classes.timeSelect}
                                            >
                                                {hours.map(hour => {
                                                    return <option
                                                        key={hour.value}
                                                        value={hour.value}
                                                        disabled={hour.disabled ? hour.disabled : false}
                                                    >
                                                        {hour.label}
                                                    </option>
                                                })}
                                            </select>
                                            <select
                                                value={course.minute}
                                                onChange={(e) => changeTimeAndUpdatePrice(course, course.hour, e.target.value)}
                                                className={classes.timeSelect}
                                            >
                                                {minutes.map(minute => {
                                                    return <option
                                                        key={minute.value}
                                                        value={minute.value}
                                                    >
                                                        {minute.label}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' sx={{ width: 40 }}>
                                        <IconButton onClick={() => {
                                            props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: '10th', value: !course['10th'] });
                                            handleUpdateCourse({ ...course, '10th': !course['10th'] });
                                        }}>
                                            {course['10th'] ? <CheckIcon sx={{ color: 'black' }} /> : <ClearIcon sx={{ color: 'black' }} />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <FormControl size='small' className={classes.tableSelect}>
                                            <MuiSelect
                                                MenuProps={selectMenuProps}
                                                value={course.tbd === 'on' ? course.tbd_days : course.tbd}
                                                onChange={(e) => updateTbd(e.target.value, course.internal_id)}
                                            >
                                                {
                                                    tbdDaysOptions.map(option => {
                                                        return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                    })
                                                }
                                            </MuiSelect>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align='center' sx={{ width: 40 }}>
                                        <IconButton onClick={(() => {
                                            props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'surcharge', value: !course.surcharge });
                                            handleUpdateCourse({ ...course, surcharge: !course.surcharge });
                                        })}>
                                            {course.surcharge ? <CheckIcon sx={{ color: 'black' }} /> : <ClearIcon sx={{ color: 'black' }} />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            value={course.price}
                                            onChange={(e) => {
                                                props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'price', value: e.target.value });
                                                handleUpdateCourse({ ...course, price: e.target.value === '' ? 0 : e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                            error={course.markup > 0 && course.price == 0}
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            value={course.markup}
                                            onChange={(e) => {
                                                props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'markup', value: e.target.value });
                                                handleUpdateCourse({ ...course, markup: e.target.value === '' ? 0 : e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                            error={course.price > 0 && course.markup == 0}
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <div>
                                            <div>{getTotalPrice(course.price, course.markup)} </div>
                                            <IconButton onClick={() => resetPrice(course)}>
                                                <DoDisturbIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <FormControl size='small' className={classes.tableSelect}>
                                            <MuiSelect
                                                MenuProps={selectMenuProps}
                                                value={course.nr_golfers}
                                                onChange={(e) => changeNrGolfersAndUpdatePrice(course, e.target.value)}
                                            >
                                                {
                                                    getGolfersOptions().map(option => {
                                                        return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                    })
                                                }
                                            </MuiSelect>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align='center' sx={{ width: 40 }}>
                                        <IconButton onClick={(() => {
                                            props.updateSelectedCourse({ itemId: itemId, id: course.internal_id, target: 'automaticPricing', value: !course.automaticPricing });
                                            handleUpdateCourse({ ...course, automaticPricing: !course.automaticPricing });
                                        })}>
                                            {course.automaticPricing ? <CheckIcon sx={{ color: 'black' }} /> : <ClearIcon sx={{ color: 'black' }} />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center' sx={{ width: 40 }}>
                                        <IconButton onClick={() => handleDeleteCourse(course)}>
                                            <DeleteIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className={classes.totalsTable}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                {totalsColumns.map(column => <TableCell align='center' key={column}>{column}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='center'>{totals.total.toFixed(2)} </TableCell>
                                <TableCell align='center'>{totals.withoutMarkup.toFixed(2)}</TableCell>
                                <TableCell align='center'>{totals.markup.toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

const enhance = compose(
    connect(
        state => ({
            resortPackages: state.packages.resortPackages,
            selectedCourses: state.quotes.selectedCourses
        }),
        dispatch => ({
            replaceSelectedCourse(payload) {
                dispatch(quotesActions.replaceSelectedCourse(payload));
            },
            updateSelectedCourse(payload) {
                dispatch(quotesActions.updateSelectedCourse(payload));
            },
            updateSelectedCourseTbd(payload) {
                dispatch(quotesActions.updateSelectedCourseTbd(payload));
            },
            updateTwoRoundsCourse(payload) {
                dispatch(quotesActions.updateTwoRoundsCourse(payload));
            },
            addSelectedCourse(payload) {
                dispatch(quotesActions.addSelectedCourse(payload));
            },
            saveSelectedCourse(payload) {
                dispatch(quotesActions.saveSelectedCourse(payload));
            },
            deleteSelectedCourse(payload) {
                dispatch(quotesActions.deleteSelectedCourse(payload));
            },
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            }
        })
    )
);

export default memo(enhance(Courses));