export const types = {

    GET_QUOTE_BY_ID: 'GET_QUOTE_BY_ID',
    GET_QUOTE_BY_ID_SUCCESS: 'GET_QUOTE_BY_ID_SUCCESS',
    GET_QUOTE_BY_ID_FAILED: 'GET_QUOTE_BY_ID_FAILED',

    SET_QUOTE_INFO: 'SET_QUOTE_INFO',
    UPDATE_QUOTE_INFO: 'UPDATE_QUOTE_INFO',

    SAVE_QUOTE: 'SAVE_QUOTE',
    SAVE_QUOTE_SUCCESS: 'SAVE_QUOTE_SUCCESS',
    SAVE_QUOTE_FAILED: 'SAVE_QUOTE_FAILED',

    UPDATE_QUOTE: 'UPDATE_QUOTE',
    UPDATE_QUOTE_SUCCESS: 'UPDATE_QUOTE_SUCCESS',
    UPDATE_QUOTE_FAILED: 'UPDATE_QUOTE_FAILED',

    DELETE_QUOTE: 'DELETE_QUOTE',
    DELETE_QUOTE_SUCCESS: 'DELETE_QUOTE_SUCCESS',
    DELETE_QUOTE_FAILED: 'DELETE_QUOTE_FAILED',

    HIDE_QUOTE: 'HIDE_QUOTE',
    HIDE_QUOTE_SUCCESS: 'HIDE_QUOTE_SUCCESS',
    HIDE_QUOTE_FAILED: 'HIDE_QUOTE_FAILED',

    GET_QUOTES: 'GET_QUOTES',
    GET_QUOTES_SUCCESS: 'GET_QUOTES_SUCCESS',
    GET_QUOTES_FAILED: 'GET_QUOTES_FAILED',

    SET_QUOTE_CONFIRM_FLAG: 'SET_QUOTE_CONFIRM_FLAG',
    SET_QUOTE_CONFIRM_FLAG_SUCCESS: 'SET_QUOTE_CONFIRM_FLAG_SUCCESS',
    SET_QUOTE_CONFIRM_FLAG_FAILED: 'SET_QUOTE_CONFIRM_FLAG_FAILED',

    SET_QUOTE_PUBLISH_FLAG: 'SET_QUOTE_PUBLISH_FLAG',
    SET_QUOTE_PUBLISH_FLAG_SUCCESS: 'SET_QUOTE_PUBLISH_FLAG_SUCCESS',
    SET_QUOTE_PUBLISH_FLAG_FAILED: 'SET_QUOTE_PUBLISH_FLAG_FAILED',

    SET_QUOTE_UNDER_REVIEW_FLAG: 'SET_QUOTE_UNDER_REVIEW_FLAG',
    SET_QUOTE_UNDER_REVIEW_FLAG_SUCCESS: 'SET_QUOTE_UNDER_REVIEW_FLAG_SUCCESS',

    GET_TEE_TIME_REMINDERS: 'GET_TEE_TIME_REMINDERS',
    GET_TEE_TIME_REMINDERS_SUCCESS: 'GET_TEE_TIME_REMINDERS_SUCCESS',
    GET_TEE_TIME_REMINDERS_FAILED: 'GET_TEE_TIME_REMINDERS_FAILED',

    GET_DUE_QUOTES: 'GET_DUE_QUOTES',
    GET_DUE_QUOTES_SUCCESS: 'GET_DUE_QUOTES_SUCCESS',
    GET_DUE_QUOTES_FAILED: 'GET_DUE_QUOTES_FAILED',

    GET_IN_PROGRESS_QUOTE_PAYMENTS: 'GET_IN_PROGRESS_QUOTE_PAYMENTS',
    GET_IN_PROGRESS_QUOTE_PAYMENTS_SUCCESS: 'GET_IN_PROGRESS_QUOTE_PAYMENTS_SUCCESS',
    GET_IN_PROGRESS_QUOTE_PAYMENTS_FAILED: 'GET_IN_PROGRESS_QUOTE_PAYMENTS_FAILED',

    HIDE_PAYMENT_IN_PROGRESS: 'HIDE_PAYMENT_IN_PROGRESS',
    HIDE_PAYMENT_IN_PROGRESS_SUCCESS: 'HIDE_PAYMENT_IN_PROGRESS_SUCCESS',

    GET_PAID_QUOTES: 'GET_PAID_QUOTES',
    GET_PAID_QUOTES_SUCCESS: 'GET_PAID_QUOTES_SUCCESS',
    GET_PAID_QUOTES_FAILED: 'GET_PAID_QUOTES_FAILED',

    ADD_GOLF_VACATION_ITEM: 'ADD_GOLF_VACATION_ITEM',
    ADD_GOLF_VACATION_ITEM_SUCCESS: 'ADD_GOLF_VACATION_ITEM_SUCCESS',
    SAVE_GOLF_VACATION_ITEM: 'SAVE_GOLF_VACATION_ITEM',
    DELETE_GOLF_VACATION_ITEM: 'DELETE_GOLF_VACATION_ITEM',
    DELETE_GOLF_VACATION_ITEM_SUCCESS: 'DELETE_GOLF_VACATION_ITEM_SUCCESS',

    CLONE_GOLF_VACATION_ITEM: 'CLONE_GOLF_VACATION_ITEM',
    CLONE_GOLF_VACATION_ITEM_REQUEST: 'CLONE_GOLF_VACATION_ITEM_REQUEST',
    CLONE_GOLF_VACATION_ITEM_SUCCESS: 'CLONE_GOLF_VACATION_ITEM_SUCCESS',
    CLONE_GOLF_VACATION_ITEM_FAILED: 'CLONE_GOLF_VACATION_ITEM_FAILED',

    SET_GOLF_VACATION_ITEMS: 'SET_GOLF_VACATION_ITEMS',
    UPDATE_GOLF_VACATION_ITEM: 'UPDATE_GOLF_VACATION_ITEM',
    UPDATE_GOLF_VACATION_ITEM_OCCUPANCY: 'UPDATE_GOLF_VACATION_ITEM_OCCUPANCY',
    UPDATE_GOLF_VACATION_ITEM_ADDITIONAL_AMOUNTS: 'UPDATE_GOLF_VACATION_ITEM_ADDITIONAL_AMOUNTS',
    REPLACE_GOLF_VACATION_ITEM: 'REPLACE_GOLF_VACATION_ITEM',
    REFRESH_GOLF_VACATION_ITEM_NAME: 'REFRESH_GOLF_VACATION_ITEM_NAME',

    SET_SELECTED_ROOMS: 'SET_SELECTED_ROOMS',
    UPDATE_SELECTED_ROOM: 'UPDATE_SELECTED_ROOM',
    REPLACE_SELECTED_ROOM: 'REPLACE_SELECTED_ROOM',

    ADD_SELECTED_ROOM: 'ADD_SELECTED_ROOM',
    ADD_SELECTED_ROOM_SUCCESS: 'ADD_SELECTED_ROOM_SUCCESS',
    SAVE_SELECTED_ROOM: 'SAVE_SELECTED_ROOM',
    DELETE_SELECTED_ROOM: 'DELETE_SELECTED_ROOM',
    DELETE_SELECTED_ROOM_SUCCESS: 'DELETE_SELECTED_ROOM_SUCCESS',

    SET_SELECTED_COURSES: 'SET_SELECTED_COURSES',
    REPLACE_SELECTED_COURSE: 'REPLACE_SELECTED_COURSE',
    UPDATE_SELECTED_COURSE: 'UPDATE_SELECTED_COURSE',
    UPDATE_SELECTED_COURSE_TBD: 'UPDATE_SELECTED_COURSE_TBD',
    ADD_SELECTED_COURSE: 'ADD_SELECTED_COURSE',
    UPDATE_TWO_ROUNDS_COURSE: 'UPDATE_TWO_ROUNDS_COURSE',

    SAVE_SELECTED_COURSE: 'SAVE_SELECTED_COURSE',
    DELETE_SELECTED_COURSE: 'DELETE_SELECTED_COURSE',

    DOWNLOAD_CONFIRMATION_PDF: 'DOWNLOAD_CONFIRMATION_PDF',
    SEND_CONFIRMATION_PDF: 'SEND_CONFIRMATION_PDF',

    SEND_VOUCHER: 'SEND_VOUCHER',
    SEND_VOUCHER_SUCCESS: 'SEND_VOUCHER_SUCCESS',

    SEND_PAYMENT_REMINDER_EMAIL: 'SEND_PAYMENT_REMINDER_EMAIL',
    SEND_PAYMENT_REMINDER_EMAIL_SUCCESS: 'SEND_PAYMENT_REMINDER_EMAIL_SUCCESS',

    SEND_AFTER_TRIP_FOLLOWUP_EMAIL: 'SEND_AFTER_TRIP_FOLLOWUP_EMAIL',
    SEND_AFTER_TRIP_FOLLOWUP_EMAIL_SUCCESS: 'SEND_AFTER_TRIP_FOLLOWUP_EMAIL_SUCCESS',

    SEND_THANK_YOU_EMAIL: 'SEND_THANK_YOU_EMAIL',
    SEND_THANK_YOU_EMAIL_SUCCESS: 'SEND_THANK_YOU_EMAIL_SUCCESS',

    SEND_FOLLOWUP_EMAIL: 'SEND_FOLLOWUP_EMAIL',
    SEND_FOLLOWUP_EMAIL_SUCCESS: 'SEND_FOLLOWUP_EMAIL_SUCCESS',
    SET_FOLLOWUP_FLAG: 'SET_FOLLOWUP_FLAG',
    SET_FOLLOWUP_FLAG_SUCCESS: 'SET_FOLLOWUP_FLAG_SUCCESS',

    SEND_PRE_TRIP_FOLLOWUP_EMAIL: 'SEND_PRE_TRIP_FOLLOWUP_EMAIL',
    SEND_GENERIC_EMAIL: 'SEND_GENERIC_EMAIL',
    SEND_CONFIRMATION_EMAIL: 'SEND_CONFIRMATION_EMAIL',
}